@import (reference) '~theme/globals/site.variables';

.generic-chart-hover-handler-line {
  pointer-events: none;
  stroke: @grey;
  opacity: 0.5;
  &.hidden {
    display: none;
  }
}

.popup-anchor {
  pointer-events: none;
  opacity: 0;
}
