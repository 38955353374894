.global-help-modal-container {
  .topics-tree {
    height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
    width: 100%;
    &.hidden {
      display: none;
    }
    .topics-tree-section {
      font-weight: bold;
      cursor: pointer;
    }
    .topics-tree-item {
      cursor: pointer;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }
}
