@import (reference) '~theme/globals/site.variables';

.global-help-button {
  @size: @28px;
  width: @size;
  height: @size;
}

.global-help-tooltip-content {
  display: flex;
  flex-direction: column;
  gap: @20px;
  .more-button {
    color: @linkColor;
    text-align: right;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

// Help button in modal header
div.modal {
  button.circular.icon.global-help-button {
    position: absolute;
    right: 1rem; // Non full-screen, close button is outside the header
  }

  &.fullscreen {
    button.circular.icon.global-help-button {
      right: 1rem; // Full-screen, no close button
    }
    i.close.icon + div.header {
      button.circular.icon.global-help-button {
        right: 3.6rem; // Full-screen, close button
      }
    }
  }
}

@media screen and (max-width: @largestTabletScreen) {
  div.modal {
    i.close.icon + div.header {
      button.circular.icon.global-help-button {
        right: 3.6rem; // Small resolution, close button inside the header
      }
    }
  }
}
