@import (reference) '~theme/globals/site.variables';
@import (reference) '../../brandPalette.less';

.multiple-line-chart {
  each(@brandPalette, .(@color, @name) {
    .multiple-line-chart-line {
      &.@{name} {
        stroke: @color;
      }
    }
    .multiple-line-chart-value-circle {
      stroke-width: 2px;
      &.value-circle-mask {
        fill: @black;
        stroke: @black;
      }
      &.graph-color-@{name} {
        fill: @white;
        stroke: @color;
        opacity: 0.7;
        &.highlight {
          opacity: 1;
        }
        &.shadow {
          opacity: 0.2;
        }
      }
    }
    .multiple-line-chart-filling.graph-fill-color-@{name} {
      stroke: none;
      fill: @color;
      fill-opacity: 0.2;
      pointer-events: none;
      &.shadow {
        fill-opacity: 0.1;
      }
    }
  });
  .multiple-line-chart-line {
    shape-rendering: auto;
    pointer-events: stroke;
    stroke-width: 2px;
    opacity: 0.7;
    &.line-mask {
      stroke: @black;
    }
    &.highlight {
      opacity: 1;
    }
    &.shadow {
      opacity: 0.2;
    }
  }
  &-mask > rect {
    fill: @white;
  }
}
