@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/collections/form.variables';

.ui.form .field {
  .form-text {
    color: lighten(@inputLabelColor, 10%);
    font-size: @12px;
    margin-top: @4px;
    &:not(:last-child) {
      margin-bottom: @4px;
    }
  }
  > .inline.fields:not(:last-child) {
    margin-bottom: 0;
  }
  .ui.toggle.checkbox + .form-text, .ui.slider.checkbox + .form-text {
    margin-top: 0;
  }
  .ui.checkbox > label {
    font-size: @labelFontSize;
    font-weight: @labelFontWeight;
  }
  &.multi-items-input {
    .no-items-message, > .fields {
      margin-bottom: 0.5em !important;
    }
  }
  &.no-input-field {
    padding-top: 0.6em;
    padding-bottom: 0.6em;
  }
}
