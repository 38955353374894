@import (reference) '~theme/globals/site.variables';

.notifier {
  position: fixed;
  top: 1em;
  right: 1em;
  z-index: 9999;
  width: 30%;
  > div {
    position: absolute !important;
    width: 100%;
    transition-property: top;
    transition-duration: 0.3s;
    overflow: hidden;
    padding: 0.5em;
    .ui.message.notification {
      margin: 0;
      padding: 1em 2.5em 1em 1em;
      align-items: normal;
      color: @invertedTextColor;
      box-shadow: @floatingShadow;
      .header {
        color: @invertedTextColor;
        margin-bottom: 5px;
      }
      .resource-name {
        font-size: @relativeLarge;
        font-weight: @bold;
        margin-bottom: 5px;
        color: @invertedTextColor;
        > a {
          color: @invertedTextColor;
          opacity: 0.8;
          &:hover {
            opacity: 1;
            text-decoration: underline;
          }
        }
      }
      .icon {
        opacity: 1;
        color: @invertedTextColor !important;
        &:not(.close) {
          font-size: @relativeLarge;
        }
        &.close {
          right: 1em;
        }
      }
      &.success {
        background: @green;
      }
      &.error {
        background: @red;
      }
      &.warning {
        background: @yellow;
      }
    }
  }
}
