@import (reference) '~theme/globals/site.variables';

.main-sidebar .main-sidebar-menu {
  display: flex;
  flex-direction: column;
  gap: @10px;
  transition: background 5s @defaultEasing;
  &:not(.expanded) {
    .menu-item {
      .text,.expand {
        display: none;
      }
    }
  }
  .dropdown,.menu-item {
    cursor: pointer;
    padding: 0;
    border-radius: @5px !important;
    background: transparent !important;
    &.active, &:hover {
      background: rgba(@white, 5%) !important;
    }
  }
  .menu-item {
    width: 100%;
    display: flex !important;
    align-items: center;
    gap: @10px;
    color: @white;
    padding: @5px @10px;
    font-size: @14px;
    line-height: @16px;
    .icon {
      height: @17px;
      margin: 0;
    }
    .expand {
      margin-left: auto;
    }
  }
  .ui.item.dropdown {
    background: transparent !important;
  }
}