@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/elements/input.variables';

.code-editor-control {
  @acePadding: @5px;
  @explorerLinkPadding: @25px;
  padding: (@verticalPadding - @lineHeightOffset - @acePadding) @horizontalPadding - @acePadding;
  background: @background;
  border: @border;
  color: @inputColor;
  border-radius: @borderRadius;
  transition: @transition;
  box-shadow: @boxShadow;
  i.icon {
    opacity: 0.5;
  }
  &:focus-within {
    border-color: @focusBorderColor;
    i.icon {
      opacity: 1;
    }
  }
  &.error, .ui.form .fields.error .field &, .ui.form .field.error & {
    background-color: @errorBackground;
    border-color: @errorBorder;
    color: @errorColor;
    box-shadow: @errorBoxShadow;
  }
  &.has-action-icon {
    padding-right: @horizontalPadding - @acePadding + @explorerLinkPadding;
    position: relative;
    &.action-group {
      padding-right: @horizontalPadding - @acePadding + @explorerLinkPadding + @explorerLinkPadding;
    }
    > .icon {
      @explorerLinkOffset: @9px;
      position: absolute;
      top: @explorerLinkOffset;
      right: @explorerLinkOffset;
      margin: 0;
    }
  }
  &.empty { // workaround for ace editor bug when active line is shown on the first focus
    .ace_editor .ace_content .ace_marker-layer { // stylelint-disable-line selector-class-pattern
      display: none;
    }
  }
}
