@import (reference) '~theme/globals/site.variables';

/* stylelint-disable selector-class-pattern */

pre.__json-pretty__ {
  margin: 0;
  font-family: Courier, monospace;
  font-size: 12px;
  overflow: auto;

  .__json-string__ {
    color: @green;
    white-space: break-spaces;
  }
  .__json-boolean__, .__json-value__ {
    color: @blue;
    font-weight: bold;
  }
  .__json-key__ {
    color: @blueDown;
  }
}
