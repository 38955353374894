@import (reference) '~theme/globals/site.variables';

.numeric-chart-layout {
  shape-rendering: crispEdges;
  touch-action: none;
  .numeric-chart-layout-time-indicator {
    stroke-width: 2px;
    stroke-dasharray: 2px;
    stroke: @black;
  }
  .numeric-chart-layout-zero-line {
    stroke: @grey;
    stroke-width: 1px;
    opacity: 0.5;
    pointer-events: none;
  }
  .visx-axis-label {
    font-size: 10px;
    fill: @grey;
  }
}
