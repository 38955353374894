@import (reference) '~theme/globals/site.variables';

.ui.grid.data-filtering-layout {
  margin: 0;

  & > .row {
    padding-bottom: 0;

    &:first-child {
      padding-top: 0;
    }

    & > .column {
      padding: 0;
    }

    &.data-table-options {
      & > div.data-filtering-options {
        flex: 1 1;
        display: flex;

        & > *:nth-child(n + 2) {
          margin-left: @15px;
        }
      }

      & > div.right.aligned.column {
        flex: 0;
        & > div.pagination-control:not(:empty) {
          white-space: nowrap;
          padding-left: 1.5rem;
        }
      }
    }

    &.applied-query-row {
      padding-bottom: 0;

      .ui.segment {
        background-color: #f9fafb;

        button.ui.button {
          min-width: 100px;
          margin-top: @12px;

          &:nth-child(2) {
            margin-left: 0.4em;
          }
        }
      }
    }

  }

  div.labelled-icon {
    i {
      margin: 0 !important;
    }
    span:not(:empty) {
      margin-left: 0.25rem;
    }
  }

}

.ui.popup.data-filtering-popup {
  position: absolute !important; // Fix for Safari

  &, &:before {
    background-color: rgb(245, 245, 245) !important;
  }
  &.hidden {
    display: none !important;
  }

  [data-popper-reference-hidden] > & {
    opacity: 0;
    animation: fadeOut @defaultDuration @defaultEasing;
    pointer-events: none;
  }
}

.data-filtering-wrapper {
  min-width: 22 * @10px;
  &.search {
    min-width: 64 * @10px;
  }


  & > h4 {
    margin-top: 0;
  }

  & > div {
    margin-bottom: 0;

    & > div.pagination-control strong {
      display: none;
    }
  }

  div.corner-buttons {
    position: absolute;
    right: 0px;
    top: 0px;

    & > button.ui.icon.button {
      &, &:hover, &:active, &:visited {
        background-color: transparent;
        padding: @16px @12px 0 0;
        cursor: pointer;
        margin: 0;
        outline: 0;
        border: 0;
        box-shadow: none;
      }
    }
  }
}
