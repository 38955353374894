@import (reference) './variables.less';

div.rich-form-fragment {
  margin-bottom: 14px;

  &.no-schema label {
    .no-schema-label();
  }

  &:last-child {
    margin-bottom: 0;
  }

  > div.field {
    margin-bottom: 5px !important;
    margin-top: 5px !important;

    > label {
      font-size: 1.3em !important;
    }
  }

  &.contains-errors > .field > label {
    color: @red !important;
  }

  > div:not(.field) {
    padding-left: 1rem;
    margin-bottom: 14px;
    border-left: dashed 1px @grey4;
  }
}
