.searchbox {
  &.ui.accordion {
    outline: none;
    margin: 0;
    padding: 0.25em 0.5em;

    > .title {
      padding: 0.5em 0.25em;
      > .icon {
        width: 1em;
      }
    }
    .content {
      padding: 0;
    }
  }

  div.search-form-container {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 1rem;
    margin-right: -1rem;
    max-height: calc(100vh - 200px);

    .ui.form {
      &:focus {
        outline: none;
      }
      > .row {
        padding-bottom: 1rem;
      }
    }
  }

  div.search-buttons {
    padding-top: 0.5rem;
  }
}
