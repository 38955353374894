.single-file-input {
  .ui.basic.table {
    height: 100%;
    padding: 0 1em;
    margin: 0;
    tr {
      border: 0;
    }
  }

  .ui.progress {
    margin: 0;
  }

  .ui.active.progress .bar {
    min-width: 2.7em;
  }
}
