@import (reference) '~theme/globals/site.variables';

.pagination-control {
  span.active-page {
    span {
      margin: 0 0.5em 0 0;
    }
  }

  span.page-size {
    strong {
      margin: 0 0.5em 0 0;
    }
  }

  &.separate {
    span.active-page {
      span {
        margin: 0 0.5em 0 0.5em;
      }
    }
  }

  span.active-page + span.page-size {
    margin-left: 1em;
  }
}

.sticky-pagination-wrapper {
  position: fixed;
  bottom: 0;
  right: 1em;
  margin-bottom: 1em;
  text-align: right;
  padding: 1rem;
  border-radius: @defaultBorderRadius;
  background-color: @black;
  z-index: 899;
  color: @white;
}

.bottom-pagination-wrapper {
  border-radius: @defaultBorderRadius;
  background-color: @black;
  z-index: 899;
  color: @white;
  padding: 1rem;
}
