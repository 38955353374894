@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/elements/button.variables';

.duration-input {
  &.custom-range {
    display: flex;
    align-items: center;
    > .ui.selection.dropdown {
      border-radius: 0;
    }
  }
  .ui.button.attached.basic.disabled {
    color: @disabledTextColor !important;
    box-shadow: @attachedBoxShadow !important;
  }
}

.duration-input-modal {
  .ui.selection.list {
    max-height: 200px;
    overflow: auto;
  }
  .advanced-selection-duration {
    .field {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      .ui.input {
        width: 100px;
        margin-right: 10px;
      }
    }
  }
}
