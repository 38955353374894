@import (reference) '~theme/globals/site.variables';

span.applied-query {
  font-weight: normal;

  &.plain {
    font-family: monospace;
  }

  & > span {
    white-space: nowrap;
  }
}
