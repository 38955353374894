@import (reference) './variables.less';

.form-array-action-button {
  padding: 5px;
  margin: 0 0 2px 2px;
  background-color: transparent;

  &:focus {
    outline: solid 1px @teal;
  }
}

div.form-fragments-array {
  margin-bottom: 14px;

  &.no-schema .field {
    > label, > label > .ui.link.button span {
      .no-schema-label();
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  > div.field {
    margin-bottom: 5px !important;

    // Required asterisk sign margins to match form fields
    &.required > label {
      margin-top: 5px;
      &:after {
        margin-left: -29px !important;
      }
    }
  
    > label {
      font-size: 1.2em !important;
      margin-bottom: 0 !important;
  
      // When there is a description rendered as (i) icon, margins must also
      // comply with the usual form field's labels
      i.circle.info.icon {
        left: -29px !important;
        bottom: 11px !important;
      }
  
      > button.ui.button {
        // Right-floating buttons (usually "add")
        &.right.floated.icon.button {
          .form-array-action-button;
          margin-top: 5px;
        }
  
        // Array field' label is rendered as a button with:
        &.link {
          padding: 5px 30px 5px 0;
          margin-right: 0;
  
          // Only textual part of the button receives focus (without the icons)
          &:focus {
            outline: none;
  
            span {
              border-radius: 3px;
              outline: solid 1px @teal;
            }
          }
  
          // * Expansion triangle (similar to accordion)
          i {
            margin: 0 3px 0 0;
            transition: all 0.3s ease;
          }
          // * Label title
          span {
            padding: 5px 2px;
          }
  
          &:not(.expanded) i {
            transform: rotate(-90deg);
            height: 20px;
          }
          // * Info (i) icon with the description tooltip (when defined in schema)
          // * Required fields' asterisk sign (when defined in schema)
        }
      }
    }
  }

  &.contains-errors > .field > label > button.link span {
    color: @red !important;
  }

  // Array's items are wrapped into a segment
  div.ui.segment {
    background-color: fade(@grey, 8%);
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 5px;

    // Single array's item
    > div.item {
      flex: 1 1 300px;
      border: solid 1px @grey5;
      border-radius: 5px;
      margin: -5px !important;
      padding: 5px;

      > div.ui.red.label {
        display: block;
        margin-bottom: 5px;
      }

      // Right floating action buttons in array items (usually "delete")
      > div.field > label > button.ui.icon.button {
        .form-array-action-button;
      }
    }
  }
}
