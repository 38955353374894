@import (reference) '~theme/globals/site.variables';
@import (reference) './variables';

.main-sidebar .popup-content {
  background-color: @submenuPopupBackground;
  cursor: default;
  max-height: 100vh;
  overflow-y: auto;
  position: absolute;
  right: 0;
  transform: translateX(100%);
  padding: @20px;
  border-radius: 0 @5px @5px 0;
  min-width: 200px;
}
