@import (reference) './variables.less';

.json-configurator {
  // Info icons in form field labels
  div.field {
    label i.info.circle.icon {
      font-size: 0.8rem;
      color: @blue;
      position: relative;
      left: 2px;
      bottom: 4px;
      pointer-events: all;
    }
  }
}
