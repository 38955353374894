@import (reference) '~theme/globals/site.variables';
@import (reference) '../../brandPalette.less';

.multiple-chart-hover-handler-circle {
  pointer-events: visible;
  fill: @white;
  stroke-width: 2px;
  &.hidden {
    display: none;
  }
}

.responsive-popup-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
}

.extra-items {
  margin-top: 5px;
}

.popup-content-cell {
  padding-right: 10px;
  border-bottom: 1px solid rgba(@grey, 0.1);
  padding-bottom: 5px;
  padding-top: 5px;
  opacity: 0.5;
  &.highlighted {
    opacity: 1;
  }

  .label {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3px;
  }
  .content-item-divider {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3px;
    margin-left: 3px;
    margin-bottom: 1px;
    background-color: @grey;
  }
}

each(@brandPalette, .(@color, @name) {
  .multiple-chart-hover-handler-circle {
    &.@{name} {
      stroke: @color;
    }
  }
  .label {
    &.@{name} {
      background-color: @color;
    }
  }
});
