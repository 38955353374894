@import (reference) '~theme/globals/site.variables';
@import '~rc-slider/assets/index.css';

.rc-slider {
  .rc-slider-track {
    background-color: @teal;
  }
  .rc-slider-handle,
  .rc-slider-dot-active,
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: @teal;
    box-shadow: none;
  }
}
