@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/elements/input.variables';
@import '../../../styles/colors.less';

@primary-light-gray: @border;
@tertiary-teal-2: @teal;
@secondary-dark-gray: @textColor;
@primary-trans: @grey;
@primary-white: @white;
@primary-blue: @blue;
@primary-dark-gray-2: @grey;
@help-search-suggestion-overlay-color: @grey;
@help-search-suggestion-spinner-color: @grey;

.global-help-page {
  height: 100%;
  overflow: auto;
  overscroll-behavior: contain;
  padding-right: @20px;
  width: 100%;
  .topic-body {
    .quick-help-nav {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.2px;
      line-height: 18px;
      text-transform: capitalize;
      border-bottom: 1px solid @primary-light-gray;
  
      .quick-help-nav-item {
        margin-right: 40px;
        padding-bottom: 13px;
        margin-bottom: -2px;
        position: relative;
        cursor: pointer;
  
        &.active {
          color: @tertiary-teal-2;
  
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: @tertiary-teal-2;
            height: 3px;
            border-radius: 5px;
          }
        }
      }
    }
  
    .quick-help-tab-content {
      padding-top: 27px;
  
      .featured-topics {
        display: flex;
        flex-direction: column;
        justify-items: center;
        gap: 12px;
        padding: 0 17px;
        list-style: none;
  
        li {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: @secondary-dark-gray;
          cursor: pointer;
  
          &:before {
            content: '\25AA';
            color: @tertiary-teal-2;
            font-weight: bold;
            display: inline-block;
            width: 13px;
            margin-left: -13px;
          }
        }
      }
    }
  
    .help-scroll-to-top {
      position: absolute;
      transform: rotate(-90deg);
      right: 35px;
      bottom: 25px;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: @primary-trans;
      font-size: 18px;
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);
      border-radius: 30px;
      visibility: visible;
      opacity: 1;
      transition: visibility 0s, opacity 0.5s linear;
      color: @tertiary-teal-2;
      cursor: pointer;
  
      .scroll-up-arrow {
        width: 18px;
  
        &:hover {
          color: @primary-white !important;
        }
      }
  
      &:hover {
        opacity: 1;
        background: @primary-blue;
        color: @primary-white;
      }
  
      &.hide-scroll {
        visibility: hidden;
        opacity: 0;
      }
    }
  
    .help-topic-table {
      margin-bottom: 15px;
      display: block;
      overflow-x: auto;
      // white-space: nowrap;
      table-layout: fixed;
      border: none !important;
  
      caption {
        display: none;
      }
  
      .help-topic-tr.even,
      .help-topic-tr.alt,
      .help-topic-tr:nth-of-type(even) {
        background: none;
      }
  
      .help-topic-th {
        border: none;
        border-bottom: none !important;
        border-right: thin solid @borderColor;
        word-break: break-word;
  
        p {
          margin-bottom: 0;
          font-size: 14px;
          padding-left: 5px;
        }
  
        &:last-child {
          border-right: none;
        }
      }
  
      .help-topic-td {
        word-wrap: break-word;
        border: none;
        border-bottom: thin solid @borderColor !important;
        border-right: thin solid @borderColor;
  
        p {
          padding-left: 5px;
          word-wrap: normal;
        }
  
        &:last-child {
          border-right: none !important;
        }
      }
  
      .help-topic-thead {
        border-bottom: 1px solid @primary-light-gray;
  
        .help-topic-tr {
          line-height: 50px;
        }
  
        .help-topic-th {
          border: 1px solid @primary-light-gray;
          vertical-align: middle;
          padding: 15px 5px;
          line-height: 18px;
  
          &:first-child {
            border-left: 0;
          }
  
          &:last-child {
            border-right: 0;
          }
        }
      }
  
      .help-topic-tbody {
        .help-topic-td {
          padding: 10px 5px;
          border-bottom: 1px solid @primary-light-gray;
  
          &:not(:last-child) {
            border-right: 1px solid @primary-light-gray;
          }
        }
      }
  
      .help-topic-tfoot {
        background-color: @grey3;
      }
  
      .tablenoborder {
        display: inline-block;
        margin: 0 !important;
        width: 100%;
        overflow-x: scroll;
      }
    }
  
    .image {
      max-width: 100%;
    }
  
    .global-help-breadcrumb-container {
      line-height: 24px;
      margin-bottom: 22px;
      font-size: 14px;
  
      .ant-breadcrumb {
        .ant-breadcrumb-link {
          color: @tertiary-teal-2;
          font-weight: 600;
          cursor: pointer;
        }
  
        .ant-breadcrumb-separator {
          color: @primary-dark-gray-2;
        }
  
        &:last-child .ant-breadcrumb-separator {
          display: inline;
        }
      }
    }
  
    .help-search-suggestions-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: @help-search-suggestion-overlay-color;
      width: 100%;
      z-index: 11;
  
      &.spinner {
        background-color: @help-search-suggestion-spinner-color;
      }
    }

    pre {
      overflow-x: auto;
    }
  }
}