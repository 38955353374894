@import (reference) '~theme/globals/site.variables';

@widgetBackground: #404040;

.main-sidebar .widget {
  background: @widgetBackground;
  border-radius: @5px;
  padding: @10px;
  color: @white;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    > .title {
      font-weight: 700;
    }
    > .expand-button {
      cursor: pointer;
    }
  }
  .content {
    margin-top: 10px;
    &:not(.expanded) {
      display: none;
    }
  }
}