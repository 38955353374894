@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/elements/input.variables';

.global-help-modal-resize-drag {
  position: fixed !important;
  z-index: 100000;
}

.global-help-modal-container {
  background: @white;
  border: @border;
  border-radius: @5px;
  box-shadow: @floatingShadow;
  display: flex;
  flex-direction: column;
  position: absolute !important;
  width: 100%;
  height: 100%;

  >.header {
    align-items: center;
    border-bottom: @border;
    cursor: move;
    display: flex;
    justify-content: space-between;
    padding: @10px @20px;
    .logo {
      display: flex;
      align-items: center;
      gap: @20px;
      svg {
        background-color: @black;
      }
    }
    .ui.header {
      margin-top: 0;
    }
  }
  >.content {
    flex: 1;
    overflow: hidden;
    margin: @20px @5px @20px @20px;
  }

  .ui.basic.disabled.button {
    color: @textColor !important;
    opacity: 0.5 !important;
  }
}
