@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/modules/dropdown.variables';

.fuzzy-search-box {
  .close.icon {
    position: relative;
    width: auto;
    font-size: @dropdownIconSize;
    margin: @dropdownIconMargin;
    opacity: @clearableIconOpacity;
    &:hover {
      opacity: @clearableIconActiveOpacity;
    }
  }
}
