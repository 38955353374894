@import (reference) '~theme/globals/site.variables';
@import (reference) '../../brandPalette.less';

.stacked-chart {
  each(@brandPalette, .(@color, @name) {
    .stacked-chart-line {
      pointer-events: visible;
      &.@{name} {
        fill: @color;
        opacity: 0.7;
        &.shadow {
          opacity: 0.2;
        }
      }
    }
  });
}
