@import (reference) '~theme/globals/site.variables';

.main-sidebar {
  &:not(.expanded) .top-sidebar-item .top-sidebar-item-icon {
    font-size: @20px;
  }
  .top-sidebar-item {
    .top-sidebar-item-icon {
      font-size: @16px;  
      line-height: @14px;
    }
    color: @white;
    font-size: @14px;
    line-height: @16px;
    &:not(.expanded) {
      display: flex;
    }
    .dropdown-container {
      width: 100%;
      align-items: center;
      padding: @5px @10px;
      cursor: pointer;
      display: flex !important;
      gap: @10px;
      .icon {
        height: @17px;
        margin: 0 !important;
      }
      .icon:last-child {
        margin-left: auto !important;
        pointer-events: none;
      }
      &.open:after, &.open:before{
        display: none;
      }
    }
  }
}