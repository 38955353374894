@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/collections/table.variables';
@import (reference) '../../graphColors.less';

.graph-popup {
  > .content {
    color: @grey;
    each(@graph-colors, {
      div.popup-range-value-@{value} {
        &:first-child {
          margin-top: 0.5em;
        }
        &:before {
          content: '';
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: @@value;
          margin-right: 5px;
        }
        &.range-highlighted {
          font-weight: @bold;
        }
      }
    });
  }
}

.timeline.graph-container {
  padding-right: 0 @cellHorizontalPadding 0 0;

  > svg {
    width: 100%;
  }

  &.expandable {
    &:hover {
      cursor: pointer;
    }
  }

  .timeline-axis {
    .visx-axis-line, .visx-axis-tick > .visx-line {
      stroke: @grey;
      opacity: 0.5;
      stroke-width: 1px;
      pointer-events: none;
    }
    .visx-axis-tick text {
      font-size: 10px;
      fill: @grey;
    }
    &.axis-bottom {
      .visx-axis-tick text {
        text-anchor: middle;
      }
    }
    &.axis-left {
      .visx-axis-tick text {
        text-anchor: end;
      }
    }
  }

  .timeline-grid line {
    stroke: @grey;
    stroke-dasharray: 2px;
    opacity: 0.2;
    pointer-events: none;
  }
}
