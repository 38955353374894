@import (reference) '~theme/globals/site.variables';

.main-sidebar-hoverable {
  border-radius: @5px;
  cursor: pointer;
  display: flex;
  &:hover {
    background: rgba(@white, 5%);
  }
  &.highlighted {
    background: rgba(@white, 5%);
  }
  &.highlighted:hover {
    background: rgba(@white, 10%);
  }
}
