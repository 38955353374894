@import (reference) '~theme/globals/site.variables';

.main-sidebar .submenu-content {
  padding: @5px @10px;
  display: flex;
  flex-direction: column;
  gap: @2px;
  font-size: @12px !important;
  line-height: @14px;
  position: relative;

  &.with-markers {
    padding-left: @28px;
    margin-top: @10px;

    > *:not(.submenu-space) {
      position: relative;
      &:after {
        content: "";
        background: @grey;
        width: @5px;
        height: @5px;
        border-radius: @2px;
        position: absolute;
        left: -@12px;
        top: calc(50% - 2.5px);
      }

      .submenu-item.secondary:before {
        content: "";
        border-top: @1px solid @grey;
        width: @13px;
        border-radius: @2px;
        position: absolute;
        left: -@10px;
        top: calc(50% - 0.5px);
        display: block;
      }
    }

    &:before {
      content: "";
      border-left: @1px solid @grey;
      position: absolute;
      left: @18px;
      top: @20px;
      bottom: @20px;  
    }
  }
}
