@import (reference) '~theme/globals/site.variables';

div.calendar-control {
  .inverted {
    &.bottom:before {
      background: @offWhite !important;
      box-shadow: -1px -1px 0 0 @borderColor !important;
    }
    
    &.top:before {
      background: @offWhite !important;
      box-shadow: 1px 1px 0 0 @borderColor !important;
    }
  }
}
