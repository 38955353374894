@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/elements/input.variables';

/* stylelint-disable selector-class-pattern */

.ace-apstra-light {
  font-family: monospace;
  background-color: @background;
  color: @textColor;
  font-size: 12px;
  line-height: 15px;
  .ui.form .fields.error .field &, .ui.form .field.error & {
    background-color: @errorBackground;
  }
  .ace_scroller.ace_scroll-left {
    box-shadow: none;
  }
  .ace_gutter {
    background: transparent;
    color: @textColor;
  }
  .ace_gutter-cell {
    border-right: @border;
    &.ace_error {
      @errorImageColor: escape(@red);
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><circle cx="8" cy="8" r="5" fill="@{errorImageColor}"/></svg>');
    }
  }
  .ace_gutter-active-line {
    background-color: @transparentBlack;
  }
  .ace_placeholder {
    color: @placeholderFocusColor;
    font-family: @inputFont;
    font-size: 14px;
    line-height: @lineHeight;
    padding: 5px 0 0 1px !important;
    margin-left: 5px !important;
    opacity: 1;
    transform: none;
    text-indent: 0;
  }
  .ace_cursor {
    color: @black;
  }
  .ace_indent-guide {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAAE0lEQVQImWP4////f4bLly//BwAmVgd1/w11/gAAAABJRU5ErkJggg==") right repeat-y;
  }

  .ace_function {
    font-weight: bold !important;
    color: @blue;
  }
  .ace_lambda {
    color: @blue;
  }
  .ace_string, .ace_tag {
    color: @greenDown;
  }
  .ace_string.ace_gq-node-name {
    font-weight: bold;
    background: fade(@greenBackground, 50%);
    border-radius: 2px;
  }
  .ace_constant.ace_language.ace_escape {
    color: darken(@greenDown, 15);
  }
  .ace_property, .ace_variable {
    color: @blueDown;
  }
  .ace_keyword-argument {
    color: @blueDown;
  }
  .ace_keyword.ace_operator {
    font-weight: bold;
    color: @purpleDown;
  }
  .ace_keyword.ace_logic {
    font-weight: bold;
    color: @purpleDown;
  }
  .ace_punctuation {
    color: @pinkDown;
  }
  .ace_keyword.ace_control {
    color: @blue;
  }
  .ace_constant.ace_language, .ace_delimiter {
    color: @orangeDown;
  }
  .ace_comment:not(.ace_delimiter) {
    color: @grey;
  }

  .ace_marker-layer {
    .ace_selection {
      background-color: @highlightBackground;
    }
    .ace_bracket {
      border: 1px solid lighten(@grey, 20);
      border-radius: 2px;
    }
    .ace_error_bracket {
      border-bottom-color: @red;
    }
    .ace_active-line {
      background: @transparentBlack;
    }
    .ace_selected-word {
      background: @background;
      border: 1px solid darken(@highlightBackground, 10);
      border-radius: 2px;
    }
    .ace_validation-error {
      position: absolute;
      border-bottom: 2px dotted fade(@red, 80);
      border-radius: 0 !important;
    }
  }

  &:not(.ace_focus):not(.ace_autocomplete) {
    .ace_placeholder {
      color: @placeholderColor;
    }
    .ace_cursor-layer, .ace_marker-layer .ace_bracket, .ace_marker-layer .ace_active-line {
      display: none;
    }
    .ace_gutter-layer .ace_gutter-active-line {
      background-color: transparent;
    }
    .ace_marker-layer .ace_selection {
      background-color: @lightGrey;
    }
    .ace_marker-layer .ace_selected-word {
      border-color: @lightGrey;
    }
    .ace_scroller:has(.ace_search) {
      .ace_marker-layer .ace_selection {
        background-color: lighten(@orange, 20%);
      }
      .ace_marker-layer .ace_selected-word {
        border-color: @yellow;
      }
    }
  }

  &.ace_autocomplete {
    .ace_completion-highlight {
      color: lighten(@blue, 10);
    }
    .ace_completion-meta {
      color: darken(@grey, 10);
    }
    .ace_marker-layer {
      .ace_line-hover {
        background: @highlightBackground;
        border-color: @highlightBackground;
      }
      .ace_active-line {
        background: @transparentBlack;
      }
    }

    .ace_text-layer {
      .ace_completion-function {
        .ace_function();
        &.ace_completion-highlight {
          color: lighten(@blue, 10);
        }
      }
      .ace_completion-string {
        .ace_string();
        &.ace_completion-highlight {
          color: lighten(@greenDown, 10);
        }
      }
      .ace_completion-keyword-argument {
        .ace_keyword-argument();
        &.ace_completion-highlight {
          color: lighten(@blueDown, 10);
        }
      }
    }
  }

  .ace_search.right {
    border-radius: 5px;
    padding: 4px;
    border: @border;
    .ace_searchbtn {
      margin-top: 2px;
    }
    .ace_search_options {
      margin-bottom: 0;
      .ace_button:first-child {
        margin-left: 0;
      }
    }
    .ace_search_field {
      box-sizing: border-box;
    }
    .ace_search_form {
      margin-right: 16px;
    }
    .ace_searchbtn_close {
      top: 7px;
      right: 2px;
    }
  }
  .ace_diff-added {
    background-color: darken(@greenBackground, 5%);
    position: absolute;
  }
  .ace_diff-removed {
    background-color: darken(@redBackground, 5%);
    position: absolute;
  }
  .ace_diff-empty {
    background-color: @offWhite;
    position: absolute;
    z-index: 10;
    pointer-events: none;
    user-select: none;
  }

  &.ace_editor.ace_diff {
    .ace_editor:last-child {
      border-left: @border;
    }
  }
}

.ace_tooltip {
  color: @textColor;
  background-color: @background;
  background-image: none;
  max-width: 300px;
  white-space: normal;
  font-size: 12px;
  border-color: @highlightBackground;
  padding: @verticalPadding @horizontalPadding;
  border-radius: @borderRadius;
  border: @border;
}

.ui.modal {
  will-change: auto;
}

.field.disabled {
  .code-editor-control, .ace-apstra-light {
    background-color: @disabledInputBackgroundColor;
    border-color: @disabledInputBorderColor;
  }
}
