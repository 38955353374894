@import (reference) '~theme/globals/site.variables';
@import (reference) './variables';

.main-sidebar {
  .submenu-space:not(:last-child) {
    margin-bottom: @10px;
  }
  :not(.submenu-space)+.main-sidebar-header {
    margin-top: @10px;
  }
  .submenu-item {
    font-size: @12px;
    padding: @5px @10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.secondary {
      padding-left: @25px;
    }

    &.header {
      font-weight: 700;
      color: @secondaryHeaderColor;
    }
  }
}
