@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/modules/checkbox.variables';

.state-toggle {
  display: flex;
  align-items: center;
  justify-content: center;

  .ui.toggle.checkbox.ui.toggle.checkbox {
    margin: 0 10px;
    input {
      &:checked ~ label:before {
        background-color: @toggleLaneBackground !important;
      }
      &:focus ~ label:before {
        background-color: @toggleFocusColor !important;
      }
      & ~ label:hover:before {
        background-color: @toggleHoverColor !important;
      }
    }
  }
}
