@import (reference) '~theme/globals/site.variables';
@import (reference) './variables';

.main-sidebar {
  position: fixed;
  height: 100%;
  width: var(--sidebar-width);
  z-index: @layoutZIndex + 1;
  &:not(.expanded) {
    .ui.vertical.menu {
      .logo .text {
        display: none;
      }
      .item.logo {
        padding-left: @9px;
      }
    }
  }
  &.with-cluster-message {
    top: @globalClusterMessageHeight;

    .ui.vertical.menu {
      top: @globalClusterMessageHeight;
      bottom: 0;
      height: auto;
    }
  }
  .submenu-popup {
    max-height: 100vh;
    overflow-y: auto;
  }
  .info {
    background: @infoBackground;
    border-radius: @5px;
    color: @white;
    display: inline;
    font-size: @9px;
    font-weight: 500;
    letter-spacing: .17em;
    line-height: @11px;
    padding: @3px @10px;
    text-transform: uppercase;
    margin: 0 auto;
  }
  .ui.vertical.menu {
    background: @sidebarBackgroundColor;
    width: var(--sidebar-width);
    padding: @20px !important;
    display: flex;
    gap: @20px;

    .item.logo {
      padding: 0;
      transition: padding-left @defaultDuration @defaultEasing;
      color: @white;
      .logo-main {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0 @10px;
        .icon {
          background-image: url('../../../styles/icons/apstra-logo.svg');
          background-repeat: no-repeat;
          width: @topLogoWidth;
          height: @topLogoHeight;
        }
        .text {
          vertical-align: top;
          text-align: center;
          font-size: @14px;
        }
      }
      .info {
        margin-left: 36px;
      }
      &:hover {
        background: none;
      }
    }
    &:after, &:before {
      display: none;
    }
  }
  .scrollable-menu {
    flex: 1;
  }
  &.expanded .scrollable-menu {
    overflow-x: hidden;
    overflow-y: auto;
    .main-sidebar-menu {
      margin: 0;
    }
  }
}
