@import (reference) '~theme/globals/site.variables';

.main-sidebar .bottom-panel {
  @panelBackground: #424242;
  display: flex;
  flex-direction: column;
  gap: @10px;
  &.expanded {
    flex-direction: row;
    :not(.right)+.right, :first-child.right {
      margin-left: auto;
    }
  }
  &:not(.expanded) {
    margin-inline: auto;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: @inputColor;
    border-radius: @5px;
    cursor: pointer;
    height: @32px;
    min-width: @32px;
    padding-inline: @5px;
    color: @white;
    max-width: @32px;
    &:hover {
      background: lighten(@inputColor, 5%);
    }
    .icon {
      height: auto;
      margin: 0;
    }
  }
  .button.expand {
    justify-self: flex-end;
  }
  .dropdown-button.dropdown-button.dropdown-button {
    border-radius: @5px !important;
    position: inherit;
    padding: 0;
  }
}
