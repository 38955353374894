@import (reference) '~theme/globals/site.variables';

.chart-timestamp-selector {
  rect {
    fill: none;
    pointer-events: visible;
  }
  .timestamp-selection-line {
    stroke: @grey;
    opacity: 0.5;
    stroke-width: 2px;
    pointer-events: none;
    &.hidden {
      display: none;
    }
  }
}
