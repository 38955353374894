@import (reference) './variables.less';

button.ui.button.link {
  &, &:hover, &:focus, &:disabled {
    box-shadow: none;
    background: transparent !important;
    border: 0;
    padding: 2px 10px;
    font: inherit;
  
    i.icon {
      padding: 0;
    }
  }
  &:focus {
    outline: solid 1px @teal;
  }

  &:disabled {
    color: @grey6 !important;
  }
}
